var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "order-table"
  }, [_c('Card', {
    attrs: {
      "padding": 20
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("最新订单详情")]), _c('Table', {
    attrs: {
      "columns": _vm.columns,
      "data": _vm.data
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };